import { render, staticRenderFns } from "@/components/layout/asignacion_agendas/creacion_agenda/creacion_agenda.html?vue&type=template&id=066d0f3f&scoped=true&"
import script from "@/components/layout/asignacion_agendas/creacion_agenda/creacion_agenda.js?vue&type=script&lang=js&"
export * from "@/components/layout/asignacion_agendas/creacion_agenda/creacion_agenda.js?vue&type=script&lang=js&"
import style0 from "@/components/layout/asignacion_agendas/creacion_agenda/creacion_agenda.css?vue&type=style&index=0&id=066d0f3f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066d0f3f",
  null
  
)

export default component.exports