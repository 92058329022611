import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
  name: 'uploadS3View',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      file: null,
      fileUpload: [],
      fileClientes: [],
      fileClientesBig: [],
      fileClientesV2: [],
      show: false,
      spinnerBigCliente: false,
      spinnerClientesV2: false,
      containerStyle: {
        backgroundColor: 'lightgrey', // Color de fondo inicial
        borderRadius: '10px' // Radio de borde
      },
      mesaggeEvent: ''
    }
  },
  watch: {
  },
  mounted () {
    this.eventStatus()
  },
  methods: {
    ...mapActions('AwsUtils', [
      'getUrlS3uploandExcel', 'sendExcelToS3'
    ]),
    ...mapActions('Cliente', [
      'setAgendasH01', 'setClientes', 'getUltimoEvento', 'setClientesV2'
    ]),
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    },
    changeBackgroundColor (color) {
      this.containerStyle.backgroundColor = color
    },
    uploadFile (event, type) {
      if (this.ultimoEvento.finalizado === false && this.ultimoEvento.error === false) {
        this.file = null
        const toast = {}
        toast.title = 'Error.'
        toast.variant = 'danger'
        toast.body = 'Aun no finaliza la carga anterior'
        this.makeToast(toast)
        return
      }
      this.show = true
      this.file = event.target.files[0]
      const fileReader = new FileReader()
      try {
        console.log('try read')
        fileReader.readAsArrayBuffer(event.target.files[0])
        console.log('end read')
      } catch (exception) {
        console.log('error')
        this.filelist = []
        return ''
      }
      fileReader.onload = (e) => {
        console.log('comienza onload')
        this.arrayBuffer = fileReader.result
        console.log(1)
        const data = new Uint8Array(this.arrayBuffer)
        const arr = []
        for (var i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i])
        }
        console.log(2)
        const bstr = arr.join('')
        const workbook = XLSX.read(bstr, { type: 'binary' })
        console.log(3)
        // para leer diferentes hojas editar la posicion del array workbook.SheetNames[0]
        const firstSheetName = workbook.SheetNames[0]
        console.log(4)
        const worksheet = workbook.Sheets[firstSheetName]
        console.log(5)
        // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }))
        // console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }))
        this.fileUpload = XLSX.utils.sheet_to_json(worksheet, { raw: true })
        this.filelist = []
        console.log(this.fileUpload)
        console.log(type)
        if (type === 'clientes') {
          console.log('es ' + type)
          this.fileClientes = this.fileUpload
        } else if (type === 'clientesBig') {
          console.log('es ' + type)
          this.fileClientesBig = this.fileUpload
        } else if (type === 'clientesV2') {
          console.log('es ' + type)
          this.fileClientesV2 = this.fileUpload
        }
        this.eventStatus()
        this.show = false
      }
    },
    sendFileClientesBig () {
      const newArray = this.fileClientesBig.map(obj => {
        return {
          cuenta: this.removeLeadingZerosAndConvertToInt(obj.NROCONTRATO),
          lectura_actual: this.removeLeadingZerosAndConvertToInt(obj.Lectura)
        }
      }).filter(obj => obj.cuenta !== null && obj.lectura_actual !== null)
      console.log(newArray)
      this.spinnerBigCliente = true
      this.setClientes(newArray).then((res) => {
        console.log(this.setClientesResponse)
        if ('status' in this.setClientesResponse) {
          if (this.setClientesResponse.status === 200) {
            const toast = {}
            toast.title = 'Creacion exitosa'
            toast.variant = 'success'
            toast.body = 'Success'
            this.makeToast(toast)
          } else {
            const toast = {}
            toast.title = 'Error en creacion'
            toast.variant = 'danger'
            toast.body = 'Error'
            this.makeToast(toast)
          }
        } else {
          if (this.setClientesResponse.response !== undefined && this.setClientesResponse.response.status === 400) {
            const toast = {}
            toast.title = 'Error en creacion'
            toast.variant = 'danger'
            toast.body = this.setClientesResponse.response.data
            this.makeToast(toast)
            this.eventStatus()
          } else if (this.setClientesResponse.request !== undefined && this.setClientesResponse.request.status === 0) {
            const toast = {}
            toast.title = 'Creacion en proceso...'
            toast.variant = 'warning'
            toast.body = 'Success'
            this.makeToast(toast)
          }
          this.eventStatus()
          this.spinnerBigCliente = false
        }
      })
    },
    removeLeadingZerosAndConvertToInt (str) {
      const intValue = parseInt(str.replace(/^0+/, ''))
      return isNaN(intValue) ? null : intValue
    },
    // uploadFile (event) {
    //   this.file = event.target.files[0]
    // },
    sendClients () {
      console.log(this.file)
      const selectedKeys = [
        'categoria_contrato',
        'cent_operativo',
        'clave_actual',
        'comuna',
        'consumo_actual',
        'cuenta',
        'diametro_medidor',
        'direccion',
        'lectura_actual',
        'num_cuenta',
        'numero_serie',
        'rut',
        'ruta_lectura',
        'segmentacion',
        'tecnologia_lectura',
        'zonacomercial',
        'zona_facturacion',
        'uso',
        'telefono1',
        'telefono2'
      ]
      const outputArray = this.fileUpload.map(obj => {
        const newObj = {}
        for (const key in obj) {
          if (selectedKeys.includes(key.toLowerCase())) {
            newObj[key.toLowerCase()] = obj[key]
          }
        }
        return newObj
      })
      console.log(outputArray)
      this.setClientes(outputArray).then((res) => {
        console.log(this.setClientesResponse)
      })
    },
    sendClientsV2 () {
      console.log(this.fileClientesV2)
      const list = this.fileClientesV2.map(this.cambiarClaves)
      console.log(list)
      this.spinnerClientesV2 = true
      this.setClientesV2(list).then((res) => {
        console.log(this.setClientesResponse)
        if ('status' in this.setClientesResponse) {
          if (this.setClientesResponse.status === 200) {
            const toast = {}
            toast.title = 'Creacion exitosa'
            toast.variant = 'success'
            toast.body = 'Success'
            this.makeToast(toast)
          } else {
            const toast = {}
            toast.title = 'Error en creacion'
            toast.variant = 'danger'
            toast.body = 'Error'
            this.makeToast(toast)
          }
        } else {
          if (this.setClientesResponse.response !== undefined && this.setClientesResponse.response.status === 400) {
            const toast = {}
            toast.title = 'Error en creacion'
            toast.variant = 'danger'
            toast.body = this.setClientesResponse.response.data
            this.makeToast(toast)
            this.eventStatus()
          } else if (this.setClientesResponse.request !== undefined && this.setClientesResponse.request.status === 0) {
            const toast = {}
            toast.title = 'Creacion en proceso...'
            toast.variant = 'warning'
            toast.body = 'Success'
            this.makeToast(toast)
          }
        }
        this.eventStatus()
        this.spinnerClientesV2 = false
      })
    },
    cambiarClaves (objeto) {
      return {
        anterior: objeto.Anterior,
        cerrado: objeto.Cerrado,
        clave_antigua: objeto['Clave Antigua'],
        cod_ruta: objeto.CodRuta,
        comuna: objeto.Comuna,
        dm: objeto.DM,
        direccion: objeto.Direccion,
        grupo: objeto.Grupo,
        id_parametro: objeto.ID,
        lectura_anterior: objeto['Lectura Anterior'],
        medidor: objeto.Medidor,
        numero: objeto['Nª'],
        ruta: objeto.Ruta,
        clave_anterior: objeto['clave Anterior'],
        valor: objeto.val,
        asignada: false
      }
    },
    eventStatus () {
      const event = {
        // tipo_evento: 'areas_verdes'
        tipo_evento: 'insert_clientes_v2'
      }
      this.getUltimoEvento(event).then((res) => {
        console.log(this.ultimoEvento)
        if (this.ultimoEvento.error === false && this.ultimoEvento.ejecutado === true && this.ultimoEvento.finalizado === false) {
          this.changeBackgroundColor('lightyellow')
          this.mesaggeEvent = 'Cargando archivo'
        } else if (this.ultimoEvento.error === false && this.ultimoEvento.ejecutado === true && this.ultimoEvento.finalizado === true) {
          this.changeBackgroundColor('lightgreen')
          this.mesaggeEvent = 'Ultimo archivo cargado'
        } else if (this.ultimoEvento.error === true) {
          this.changeBackgroundColor('lightcoral')
          this.mesaggeEvent = 'Hubo un error en la carga del archivo'
        }
      })
    }
  },
  computed: {
    ...mapState('AwsUtils', ['urlS3uploandExcel']),
    ...mapState('AwsUtils', ['responseSendExceltoS3']),
    ...mapState('Cliente', ['setClientesResponse']),
    ...mapState('Cliente', ['ultimoEvento'])
  }
}
