import { mapActions, mapState } from 'vuex'

export default {
  name: 'uploadS3View',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      file: null
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    ...mapActions('AwsUtils', [
      'getUrlS3uploandExcel', 'sendExcelToS3'
    ]),
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    },
    uploadFile (event) {
      this.file = event.target.files[0]
    },
    sendToS3 () {
      this.getUrlS3uploandExcel().then((res) => {
        const url = this.urlS3uploandExcel // La URL presignada
        const formData = new FormData()
        formData.append('file', this.file)
        console.log(formData)
        const data = {
          url: url,
          data: formData,
          type: this.file.type
        }
        this.sendExcelToS3(data).then((res) => {
          console.log(this.responseSendExceltoS3)
        })
      })
    }
  },
  computed: {
    ...mapState('AwsUtils', ['urlS3uploandExcel']),
    ...mapState('AwsUtils', ['responseSendExceltoS3'])
  }
}
