import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import AsignacionAgendasView from '../views/AsignacionAgendasView.vue'
import uploadS3View from '../views/uploadS3View.vue'
import uploadClientesView from '../views/uploadClientesView.vue'
import creacionClientesView from '../views/creacionClientesView.vue'
import visitasView from '../views/visitasView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'asignacion',
    component: AsignacionAgendasView
  },
  {
    path: '/uploadS3',
    name: 'uploadS3',
    component: uploadS3View
  },
  {
    path: '/uploadClientes',
    name: 'uploadClientes',
    component: uploadClientesView
  },
  {
    path: '/cliente/crear',
    name: 'createCliente',
    component: creacionClientesView
  },
  {
    path: '/visitas',
    name: 'visitas',
    component: visitasView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
