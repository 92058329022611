import { mapActions, mapState } from 'vuex'

export default {
  name: 'CreacionAgendaV2View',
  components: {
  },
  props: {
    comunasV2: null,
    codRutas: null,
    devices: null,
    rutasV2: null
  },
  data: function () {
    return {
      selectedInspector: null,
      inspectores: [
        { value: null, text: 'Seleccionar Inspector' }
      ],
      selectedComuna: null,
      comunas: [
        { value: null, text: 'Seleccionar Comunas' }
      ],
      selectedGrupo: null,
      selectedRuta: null,
      grupo: [
        { value: null, text: 'Seleccionar Ruta' }
      ],
      rutasList: [
        { value: null, text: 'Seleccionar Rutas' }
      ],
      cantidad_agendas: 1,
      dia_agenda: '',
      comunasArray: [],
      rutasArray: [],
      cantidad_registros: null,
      cantidad_registros_comunas: null,
      cantidad_registros_rutas: null,
      ultima_ruta: null
    }
  },
  watch: {
    selectedComuna: function (val) {
      if (this.selectedComuna !== null) {
        this.comunasArray.push(this.selectedComuna)
        this.selectedComuna = null
        this.actualizaContadorComuna()
      }
      this.updateRutas()
      this.verificarAgendas()
      this.rutasByComunas()
    },
    selectedRuta: function (val) {
      if (this.selectedRuta !== null) {
        this.rutasArray.push(this.selectedRuta)
        this.ultima_ruta = this.selectedRuta
        this.selectedRuta = null
        this.actualizaContadorRuta()
      }
      this.verificarAgendas()
    },
    selectedGrupo: function (val) {
      this.verificarAgendas()
    },
    selectedInspector: function (val) {
      if (this.selectedInspector === null) {
        this.comunasArray = []
        this.selectedComuna = null
        this.selectedGrupo = null
        this.cantidad_registros = null
      }
    }
  },
  mounted () {
    // console.log(this.comunasApi)
    // console.log(this.grupos)
    this.getCountComunasV2().then((res) => {
      console.log(this.comunasApiCount)
      this.comunasApiCount.forEach(e => {
        this.comunas.push({
          value: e.comuna,
          text: e.comuna + ' Cantidad: ' + e.cantidad
        })
      })
    })
    // this.comunasV2.forEach(e => {
    //   this.comunas.push({
    //     value: e,
    //     text: e
    //   })
    // })
    // this.getCountRuta.forEach(e => {
    //   console.log(this.rutasCount)
    // })
    this.rutasV2.forEach(e => {
      this.grupo.push({
        value: e,
        text: e
      })
    })
    console.log(this.devices)
    this.devices.forEach(e => {
      this.inspectores.push({
        value: e.device_alias,
        text: e.device_alias
      })
    })
    console.log(this.devices)
  },
  methods: {
    ...mapActions('Cliente', [
      'getAgendasDisponibles', 'getAgendasDisponiblesV2', 'getAgendasDisponiblesFilterV2'
    ]),
    ...mapActions('Utils', [
      'getRutaByComunas', 'getRuta', 'getCountRuta', 'getCountComunasV2'
    ]),
    updateRutas () {
      if (this.comunasArray.length > 0) {
        this.rutasByComunas(this.comunasArray)
      } else {
        this.rutasByComunas()
      }
    },
    agregar () {
      const data = {
        device_alias: this.selectedInspector,
        comunas: this.comunasArray,
        ruta: this.selectedGrupo,
        rutas: this.rutasArray,
        cantidad_agendas: parseInt(this.cantidad_agendas),
        dia_agenda: this.dia_agenda + ' 00:00:00',
        enviado: false,
        ultima_ruta: this.ultima_ruta
      }
      console.log(data)
      if (this.validaParametros()) {
        const toast = {}
        toast.title = 'Asignacion exitosa'
        toast.variant = 'success'
        toast.body = 'Success'
        this.makeToast(toast)
        this.$emit('agregar', data)
      } else {
        const toast = {}
        toast.title = 'Error.'
        toast.variant = 'danger'
        toast.body = 'Faltan campos por completar o corregir'
        this.makeToast(toast)
      }
    },
    actualizaContadorRuta () {
      const data = {}
      if (this.rutasArray.length > 0) {
        data.rutas = this.rutasArray
      }
      console.log(data)
      this.getAgendasDisponiblesFilterV2(data).then((res) => {
        console.log('ByRuta')
        console.log(res)
        this.cantidad_registros_rutas = res.data.agendas_disponibles
      })
    },
    actualizaContadorComuna () {
      const data = {}
      if (this.comunasArray.length > 0) {
        data.comunas = this.comunasArray
      }
      console.log(data)
      this.getAgendasDisponiblesFilterV2(data).then((res) => {
        console.log('ByComuna')
        console.log(res)
        this.cantidad_registros_comunas = res.data.agendas_disponibles
      })
    },
    eliminaComuna (columna, index) {
      console.log(columna)
      console.log(index)
      this.comunasArray.splice(index, 1)
      this.updateRutas()
      this.verificarAgendas()
      this.actualizaContadorComuna()
      this.rutasByComunas()
    },
    eliminaRutas (ruta, index) {
      console.log(ruta)
      console.log(index)
      this.rutasArray.splice(index, 1)
      this.ultima_ruta = this.rutasArray[this.rutasArray.length - 1]
      this.verificarAgendas()
      this.actualizaContadorRuta()
    },
    rutasByComunas (comunas) {
      console.log(comunas)
      console.log(this.comunasArray)
      const c = { comunas: this.comunasArray }
      console.log(c)
      this.getCountRuta(c).then((res) => {
        console.log(this.rutasCount)
        this.grupo = [{ value: null, text: 'Seleccionar Ruta' }]
        this.rutasCount.forEach(e => {
          this.grupo.push({
            value: e.ruta,
            text: e.ruta + ' Cantidad: ' + e.cantidad
          })
        })
      })
    },
    verificarAgendas () {
      console.log('verificarAgendas')
      if (this.selectedInspector !== null && (this.comunasArray.length > 0 || this.rutasArray.length > 0)) {
        const data = {}
        if (this.comunasArray.length > 0) {
          data.comunas = this.comunasArray
        }
        if (this.rutasArray.length > 0) {
          data.rutas = this.rutasArray
        }
        console.log(data)
        this.getAgendasDisponiblesV2(data).then((res) => {
          console.log(this.agendasDisponibles)
          this.cantidad_registros = this.agendasDisponibles.agendas_disponibles
        })
      }
    },
    validaParametros () {
      if (this.selectedInspector !== null && this.comunasArray.length > 0 && this.rutasArray.length > 0 &&
        this.dia_agenda !== '' && this.cantidad_agendas <= this.cantidad_registros) {
        return true
      } else {
        return false
      }
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('Cliente', ['agendasDisponibles']),
    ...mapState('Utils', ['rutas']),
    ...mapState('Utils', ['rutasCount']),
    ...mapState('Utils', ['comunasApiCount']),
    arrayComunaComputed () {
      console.log(this.selectedComuna)
      return this.comunasArray.push(this.selectedComuna)
    },
    arrayRutaComputed () {
      console.log(this.selectedRuta)
      return this.rutasArray.push(this.selectedRuta)
    },
    validaCantiadadComputed () {
      console.log(this.cantidad_agendas <= this.cantidad_registros)
      return (this.cantidad_agendas <= this.cantidad_registros)
    }
  }
}
