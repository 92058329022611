import store from '@/store'
import axios from 'axios'
// import router from '@/router/'

export default {
  namespaced: true,
  state: {
    comunasApi: [],
    comunasApiCount: [],
    grupos: [],
    devices: [],
    solicitudesAgendas: [],
    codRutas: [],
    comunasV2: [],
    rutas: [],
    rutasCount: []
  },
  mutations: {
    set_accion_comunas (state, comunas) {
      state.comunasApi = comunas
    },
    set_accion_comunasCount_v2 (state, comunas) {
      state.comunasApiCount = comunas
    },
    set_accion_comunas_v2 (state, comunas) {
      state.comunasV2 = comunas
    },
    set_accion_codRutas (state, codRutas) {
      state.codRutas = codRutas
    },
    set_accion_rutas (state, rutas) {
      state.rutas = rutas
    },
    set_accion_rutasCount (state, rutas) {
      state.rutasCount = rutas
    },
    set_accion_grupos (state, grupos) {
      state.grupos = grupos
    },
    set_accion_devices (state, devices) {
      state.devices = devices
    },
    set_accion_solicitudesAgendas (state, solicitudesAgendas) {
      state.solicitudesAgendas = solicitudesAgendas
    }
  },
  actions: {
    async getComunas ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/helpbank/getComunas`
          // headers: {
          //   Authorization: 'Bearer ' + store.state.token
          // }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getComunas')
          // console.log(response.data)
          commit('set_accion_comunas', response.data)
          return true
        } else {
          console.log('por else getComunas')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getComunas')
        // router.push({ path: '/' })
        return false
      }
    },
    async getComunasV2 ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/helpbank/getComunasV2`
          // headers: {
          //   Authorization: 'Bearer ' + store.state.token
          // }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getComunasV2')
          // console.log(response.data)
          commit('set_accion_comunas_v2', response.data)
          return true
        } else {
          console.log('por else getComunasV2')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getComunasV2')
        // router.push({ path: '/' })
        return false
      }
    },
    async getCountComunasV2 ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/helpbank/getCountComunasV2`
          // headers: {
          //   Authorization: 'Bearer ' + store.state.token
          // }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getCountComunasV2')
          // console.log(response.data)
          commit('set_accion_comunasCount_v2', response.data)
          return true
        } else {
          console.log('por else getCountComunasV2')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getCountComunasV2')
        // router.push({ path: '/' })
        return false
      }
    },
    async getCodRuta ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/helpbank/getCodRuta`
          // headers: {
          //   Authorization: 'Bearer ' + store.state.token
          // }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getCodRuta')
          // console.log(response.data)
          commit('set_accion_codRutas', response.data)
          return true
        } else {
          console.log('por else getCodRuta')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getCodRuta')
        // router.push({ path: '/' })
        return false
      }
    },
    async getRuta ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/helpbank/getRuta`
          // headers: {
          //   Authorization: 'Bearer ' + store.state.token
          // }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getRuta')
          // console.log(response.data)
          commit('set_accion_rutas', response.data)
          return true
        } else {
          console.log('por else getRuta')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getRuta')
        // router.push({ path: '/' })
        return false
      }
    },
    async getCountRuta ({ commit, dispatch, state }, comunas) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/helpbank/getCountRuta`,
          data: comunas
          // headers: {
          //   Authorization: 'Bearer ' + store.state.token
          // }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getCountRuta')
          // console.log(response.data)
          commit('set_accion_rutasCount', response.data)
          return true
        } else {
          console.log('por else getCountRuta')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getCountRuta')
        // router.push({ path: '/' })
        return false
      }
    },
    async getRutaByComunas ({ commit, dispatch, state }, comunas) {
      console.log(comunas)
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/helpbank/getRuta`,
          data: comunas
          // headers: {
          //   Authorization: 'Bearer ' + store.state.token
          // }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getRutaByComunas')
          // console.log(response.data)
          commit('set_accion_rutas', response.data)
          return true
        } else {
          console.log('por else getRutaByComunas')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getRutaByComunas')
        // router.push({ path: '/' })
        return false
      }
    },
    async getGrupos ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/helpbank/getGrupos`
          // headers: {
          //   Authorization: 'Bearer ' + store.state.token
          // }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getGrupos')
          // console.log(response.data)
          commit('set_accion_grupos', response.data)
          return true
        } else {
          console.log('por else getGrupos')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getGrupos')
        // router.push({ path: '/' })
        return false
      }
    },
    async getDevices ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/helpbank/getDevices`
          // headers: {
          //   Authorization: 'Bearer ' + store.state.token
          // }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getDevices')
          // console.log(response.data)
          commit('set_accion_devices', response.data)
          return true
        } else {
          console.log('por else getDevices')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getDevices')
        // router.push({ path: '/' })
        return false
      }
    },
    async getSolicitudesAgendas ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/helpbank/getSolicitudesAgendas`
          // headers: {
          //   Authorization: 'Bearer ' + store.state.token
          // }
        })
        if (response.status === 200) {
          console.log('success stuff api getSolicitudesAgendas')
          commit('set_accion_solicitudesAgendas', response.data)
          return true
        } else {
          console.log('por else getSolicitudesAgendas')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getSolicitudesAgendas')
        return false
      }
    }
  },
  getters: {
  }
}
