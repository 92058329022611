import store from '@/store'
import axios from 'axios'
// import router from '@/router/'

export default {
  namespaced: true,
  state: {
    urlS3uploandExcel: [],
    responseSendExceltoS3: []
  },
  mutations: {
    set_accion_urlS3uploandExcel (state, urlS3uploandExcel) {
      state.urlS3uploandExcel = urlS3uploandExcel
    },
    set_accion_responseSendExceltoS3 (state, responseSendExceltoS3) {
      state.responseSendExceltoS3 = responseSendExceltoS3
    }
  },
  actions: {
    async getUrlS3uploandExcel ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/helpbank/getUrl`
        })
        // const response = await axios.get(`${store.state.host}/api/helpbank/crear/agenda/H01`, data)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setAgendasH01')
          console.log(response.data)
          commit('set_accion_urlS3uploandExcel', response.data)
          return true
        } else {
          console.log('por else getUrlS3uploandExcel')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getUrlS3uploandExcel')
        // router.push({ path: '/' })
        return false
      }
    },
    async sendExcelToS3 ({ commit, dispatch, state }, data) {
      try {
        console.log(data.url)
        const response = await axios.put(data.url, data.data, {
          headers: {
            'Content-Type': data.type
          }
        })
        // const response = await axios.get(`${store.state.host}/api/helpbank/crear/agenda/H01`, data)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api sendExcelToS3')
          console.log(response)
          commit('set_accion_responseSendExceltoS3', response)
          return true
        } else {
          console.log('por else sendExcelToS3')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch sendExcelToS3')
        // router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
