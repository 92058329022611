import * as XLSX from 'xlsx/xlsx.mjs'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'VisitasView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      inspectores: ['device_alias1', 'device_alias2', 'device_alias3'],
      comunas: ['comuna1', 'comuna2', 'comuna3'],
      grupo: ['grupo1', 'grupo2', 'grupo3'],
      // comunasV2: ['comuna4', 'comuna5', 'comuna6'],
      // codRutas: [1, 2],
      cantidad_agendas: 0,
      fields: [
        {
          key: 'id_cliente',
          label: 'Cliente ID',
          sortable: false
        },
        {
          key: 'fecha_creacion',
          label: 'Fecha Creacion',
          sortable: false
        },
        {
          key: 'fecha_actualizacion',
          label: 'Fecha Actualizacion',
          sortable: false
        },
        {
          key: 'numero',
          label: 'Numero',
          sortable: false
        },
        {
          key: 'id_visita',
          label: 'Visita ID',
          sortable: false
        },
        {
          key: 'id_parametro',
          label: 'ID Parametro',
          sortable: false
        },
        {
          key: 'ruta',
          label: 'Ruta',
          sortable: false
        },
        {
          key: 'condicion_medidor',
          label: 'Condicion Medidor',
          sortable: false
        },
        {
          key: 'valor',
          label: 'Valor',
          sortable: false
        },
        {
          key: 'lectura_anterior',
          label: 'Lectura Anterior',
          sortable: false
        },
        {
          key: 'clave_anterior',
          label: 'Clave Anterior',
          sortable: false
        },
        {
          key: 'anterior',
          label: 'Anterior',
          sortable: false
        },
        {
          key: 'clave_antigua',
          label: 'Clave Antigua',
          sortable: false
        },
        {
          key: 'medidor',
          label: 'Medidor',
          sortable: false
        },
        {
          key: 'dm',
          label: 'Dm',
          sortable: false
        },
        {
          key: 'direccion',
          label: 'Direccion',
          sortable: false
        },
        {
          key: 'comuna',
          label: 'Comuna',
          sortable: false
        },
        {
          key: 'cod_ruta',
          label: 'Cod Ruta',
          sortable: false
        },
        {
          key: 'grupo',
          label: 'Grupo',
          sortable: false
        },
        {
          key: 'cerrado',
          label: 'Cerrado',
          sortable: false
        },
        {
          key: 'asignada',
          label: 'Asignada',
          sortable: false
        },
        {
          key: 'id_visita',
          label: 'ID Visita',
          sortable: false
        },
        {
          key: 'lectura',
          label: 'Lectura',
          sortable: false
        },
        {
          key: 'clave',
          label: 'Clave',
          sortable: false
        },
        {
          key: 'observaciones',
          label: 'Observaciones',
          sortable: false
        },
        {
          key: 'inspector',
          label: 'Inspector',
          sortable: false
        },
        {
          key: 'fecha_visita',
          label: 'Fecha Visita',
          sortable: false
        },
        {
          key: 'estado_visita',
          label: 'Estado Visita',
          sortable: false
        }
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'fecha',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      activeColor: 'arial',
      fontSize: 13
    }
  },
  watch: {
  },
  mounted () {
    this.getVisitasPlusNow().then((res) => {
      console.log(this.visitas)
      this.items = this.visitas
    })
  },
  methods: {
    ...mapActions('Visitas', [
      'getVisitasPlusNow'
    ]),
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants') && key !== 'docs') {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'agendamientos')
      XLSX.writeFile(wb, 'agendamientos.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('Visitas', ['visitas'])
  }
}
