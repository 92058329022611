import store from '@/store'
import axios from 'axios'
// import router from '@/router/'

export default {
  namespaced: true,
  state: {
    setAgendas: [],
    setClientesResponse: [],
    agendasDisponibles: [],
    setClienteResponse: [],
    ultimoEvento: []
  },
  mutations: {
    set_accion_setAgendas (state, setAgendas) {
      state.setAgendas = setAgendas
    },
    set_accion_setClientesResponse (state, setClientesResponse) {
      state.setClientesResponse = setClientesResponse
    },
    set_accion_agendasDisponibles (state, agendasDisponibles) {
      state.agendasDisponibles = agendasDisponibles
    },
    set_accion_setClienteResponse (state, setClienteResponse) {
      state.setClienteResponse = setClienteResponse
    },
    set_accion_ultimoEvento (state, ultimoEvento) {
      state.ultimoEvento = ultimoEvento
    }
  },
  actions: {
    async setAgendasH01 ({ commit, dispatch, state }, data) {
      console.log(data)
      try {
        const response = await axios.post(`${store.state.host}/api/helpbank/crear/agenda/H01`, data)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setAgendasH01')
          console.log(response.data)
          commit('set_accion_setAgendas', response.data)
          return true
        } else {
          console.log('por else setAgendasH01')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setAgendasH01')
        // router.push({ path: '/' })
        return false
      }
    },
    async setClientes ({ commit, dispatch, state }, data) {
      console.log(data)
      try {
        const response = await axios.post(`${store.state.host}/api/helpbank/updateClientes`, data)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setClientes')
          console.log(response.data)
          commit('set_accion_setClientesResponse', response)
          return true
        } else {
          console.log('por else setClientes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setClientes')
        commit('set_accion_setClientesResponse', err)
        // router.push({ path: '/' })
        return false
      }
    },
    async setCliente ({ commit, dispatch, state }, data) {
      console.log(data)
      try {
        const response = await axios.post(`${store.state.host}/api/helpbank/crear/cliente`, data)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setCliente')
          console.log(response.data)
          commit('set_accion_setClienteResponse', response)
          return true
        } else {
          console.log('por else setCliente')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setCliente')
        commit('set_accion_setClienteResponse', err)
        // router.push({ path: '/' })
        return false
      }
    },
    async getAgendasDisponibles ({ commit, dispatch, state }, data) {
      console.log(data)
      try {
        const response = await axios.post(`${store.state.host}/api/helpbank/clientes/verificarDisponibilidad`, data)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAgendasDisponibles')
          console.log(response.data)
          commit('set_accion_agendasDisponibles', response.data)
          return true
        } else {
          console.log('por else getAgendasDisponibles')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAgendasDisponibles')
        // router.push({ path: '/' })
        return false
      }
    },
    async getAgendasDisponiblesV2 ({ commit, dispatch, state }, data) {
      console.log(data)
      try {
        const response = await axios.post(`${store.state.host}/api/helpbank/clientes/verificarDisponibilidadV2`, data)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAgendasDisponibles')
          console.log(response.data)
          commit('set_accion_agendasDisponibles', response.data)
          return true
        } else {
          console.log('por else getAgendasDisponibles')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAgendasDisponibles')
        // router.push({ path: '/' })
        return false
      }
    },
    async getAgendasDisponiblesFilterV2 ({ commit, dispatch, state }, data) {
      console.log(data)
      try {
        const response = await axios.post(`${store.state.host}/api/helpbank/clientes/verificarDisponibilidadV2`, data)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getAgendasDisponiblesFilterV2')
          console.log(response.data)
          return response
        } else {
          console.log('por else getAgendasDisponiblesFilterV2')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getAgendasDisponiblesFilterV2')
        // router.push({ path: '/' })
        return err
      }
    },
    async getUltimoEvento ({ commit, dispatch, state }, data) {
      console.log(data)
      try {
        const response = await axios.post(`${store.state.host}/api/helpbank/clientes/estadoEvento`, data)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getUltimoEvento')
          console.log(response.data)
          commit('set_accion_ultimoEvento', response.data)
          return true
        } else {
          console.log('por else getUltimoEvento')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getUltimoEvento')
        // router.push({ path: '/' })
        return false
      }
    },
    async setClientesV2 ({ commit, dispatch, state }, data) {
      console.log(data)
      try {
        const response = await axios.post(`${store.state.host}/api/helpbank/updateClientesV2`, data)
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api setClientes')
          console.log(response.data)
          commit('set_accion_setClientesResponse', response)
          return true
        } else {
          console.log('por else setClientes')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch setClientes')
        commit('set_accion_setClientesResponse', err)
        // router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
