import Vue from 'vue'
import Vuex from 'vuex'
import Utils from '@/modules/Utils.js'
import Cliente from '@/modules/Cliente.js'
import AwsUtils from '@/modules/AwsUtils.js'
import Visitas from '@/modules/Visitas.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: '',
    password: '',
    token: 'token',
    // host: 'localhost:8095'
    host: 'https://api.appdmetrix.com'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Utils,
    Cliente,
    AwsUtils,
    Visitas
  }
})
