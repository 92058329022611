import store from '@/store'
import axios from 'axios'
// import router from '@/router/'

export default {
  namespaced: true,
  state: {
    visitas: []
  },
  mutations: {
    set_accion_visitas (state, visitas) {
      state.visitas = visitas
    }
  },
  actions: {
    async getVisitasPlusNow ({ commit, dispatch, state }) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.host}/api/helpbank/visitasPlusNow`
          // headers: {
          //   Authorization: 'Bearer ' + store.state.token
          // }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api getVisitasPlusNow')
          // console.log(response.data)
          commit('set_accion_visitas', response.data)
          return true
        } else {
          console.log('por else getVisitasPlusNow')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getVisitasPlusNow')
        // router.push({ path: '/' })
        return false
      }
    }
  },
  getters: {
  }
}
