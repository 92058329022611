import { mapActions, mapState } from 'vuex'

export default {
  name: 'CreacionAgendaView',
  components: {
  },
  props: {
    comunasApi: null,
    grupos: null,
    devices: null
  },
  data: function () {
    return {
      selectedInspector: null,
      inspectores: [
        { value: null, text: 'Seleccionar Inspector' }
      ],
      selectedComuna: null,
      comunas: [
        { value: null, text: 'Seleccionar Comunas' }
      ],
      selectedGrupo: null,
      grupo: [
        { value: null, text: 'Seleccionar Grupo' }
      ],
      cantidad_agendas: 1,
      dia_agenda: '',
      comunasArray: [],
      cantidad_registros: null
    }
  },
  watch: {
    selectedComuna: function (val) {
      if (this.selectedComuna !== null) {
        this.comunasArray.push(this.selectedComuna)
        this.selectedComuna = null
      }
      this.verificarAgendas()
    },
    selectedGrupo: function (val) {
      this.verificarAgendas()
    },
    selectedInspector: function (val) {
      if (this.selectedInspector === null) {
        this.comunasArray = []
        this.selectedComuna = null
        this.selectedGrupo = null
        this.cantidad_registros = null
      }
    }
  },
  mounted () {
    // console.log(this.comunasApi)
    // console.log(this.grupos)
    this.comunasApi.forEach(e => {
      this.comunas.push({
        value: e,
        text: e
      })
    })
    this.grupos.forEach(e => {
      this.grupo.push({
        value: e,
        text: e
      })
    })
    console.log(this.devices)
    this.devices.forEach(e => {
      this.inspectores.push({
        value: e.device_alias,
        text: e.device_alias
      })
    })
  },
  methods: {
    ...mapActions('Cliente', [
      'getAgendasDisponibles'
    ]),
    agregar () {
      const data = {
        device_alias: this.selectedInspector,
        comunas: this.comunasArray,
        grupo: this.selectedGrupo,
        cantidad_agendas: parseInt(this.cantidad_agendas),
        dia_agenda: this.dia_agenda + ' 00:00:00',
        enviado: false
      }
      if (this.validaParametros()) {
        const toast = {}
        toast.title = 'Asignacion exitosa'
        toast.variant = 'success'
        toast.body = 'Success'
        this.makeToast(toast)
        this.$emit('agregar', data)
      } else {
        const toast = {}
        toast.title = 'Error.'
        toast.variant = 'danger'
        toast.body = 'Faltan campos por completar o corregir'
        this.makeToast(toast)
      }
    },
    eliminaComuna (columna, index) {
      console.log(columna)
      console.log(index)
      this.comunasArray.splice(index, 1)
      this.verificarAgendas()
    },
    verificarAgendas () {
      if (this.selectedInspector !== null && this.comunasArray.length > 0 && this.selectedGrupo !== null) {
        const data = {
          comunas: this.comunasArray,
          grupo: this.selectedGrupo
        }
        this.getAgendasDisponibles(data).then((res) => {
          console.log(this.agendasDisponibles)
          this.cantidad_registros = this.agendasDisponibles.agendas_disponibles
        })
      }
    },
    validaParametros () {
      if (this.selectedInspector !== null && this.comunasArray.length > 0 && this.selectedGrupo !== null &&
        this.dia_agenda !== '' && this.cantidad_agendas <= this.cantidad_registros) {
        return true
      } else {
        return false
      }
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('Cliente', ['agendasDisponibles']),
    arrayComunaComputed () {
      console.log(this.selectedComuna)
      return this.comunasArray.push(this.selectedComuna)
    },
    validaCantiadadComputed () {
      console.log(this.cantidad_agendas <= this.cantidad_registros)
      return (this.cantidad_agendas <= this.cantidad_registros)
    }
  }
}
