import CreacionAgendaView from '@/components/layout/asignacion_agendas/creacion_agenda/CreacionAgendaView.vue'
import CreacionAgendaV2View from '@/components/layout/asignacion_agendas/creacion_agenda_v2/CreacionAgendaV2View.vue'
import * as XLSX from 'xlsx/xlsx.mjs'
import { mapActions, mapState } from 'vuex'
// import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  name: 'AsignacionAgendasView',
  components: {
    CreacionAgendaView,
    CreacionAgendaV2View
  },
  props: {
  },
  data: function () {
    return {
      inspectores: ['device_alias1', 'device_alias2', 'device_alias3'],
      comunas: ['comuna1', 'comuna2', 'comuna3'],
      grupo: ['grupo1', 'grupo2', 'grupo3'],
      // comunasV2: ['comuna4', 'comuna5', 'comuna6'],
      // codRutas: [1, 2],
      cantidad_agendas: 0,
      fields: [
        {
          key: 'device_alias',
          label: 'Inspector',
          sortable: false
        },
        {
          key: 'comunas',
          label: 'Comunas',
          sortable: false
        },
        {
          key: 'grupo',
          label: 'Grupo',
          sortable: false
        },
        // {
        //   key: 'ruta',
        //   label: 'Ruta',
        //   sortable: false
        // },
        {
          key: 'rutas',
          label: 'Rutas',
          sortable: false
        },
        {
          key: 'dia_agenda',
          label: 'Día Agenda',
          sortable: false
        },
        {
          key: 'cantidad_agendas',
          label: 'Cantiada Agendas',
          sortable: false
        }
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'fecha',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      crearAsignacionModal: {
        id: 'crear-asignacion-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13
    }
  },
  watch: {
  },
  mounted () {
    this.getSolicitudesAgendas().then((res) => {
      console.log(this.solicitudesAgendas)
      this.items = this.solicitudesAgendas
    })
    this.getComunas().then((res) => {
      console.log(this.comunasApi.sort())
    })
    this.getComunasV2().then((res) => {
      console.log(this.comunasV2.sort())
    })
    this.getGrupos().then((res) => {
      console.log(this.grupos.sort())
    })
    this.getCodRuta().then((res) => {
      console.log(this.codRutas.sort())
    })
    this.getRuta().then((res) => {
      console.log(this.rutas.sort())
    })
    this.getDevices().then((res) => {
      console.log(this.devices.sort())
    })
  },
  methods: {
    ...mapActions('Utils', [
      'getComunas', 'getGrupos', 'getDevices', 'getSolicitudesAgendas', 'getComunasV2', 'getCodRuta', 'getRuta'
    ]),
    ...mapActions('Cliente', [
      'setAgendasH01'
    ]),
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    CrearAsignacionModal () {
      this.crearAsignacionModal.title = 'Asignación agendas'
      // this.crearAsignacionModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.crearAsignacionModal.id)
    },
    hideCrearAsignacionModal () {
      this.$refs[this.crearAsignacionModal.id].hide()
    },
    agregar (data) {
      console.log(data)
      this.items.push(data)
      this.hideCrearAsignacionModal()
    },
    setAgendasEnviadas () {
      this.items.forEach(function (objeto) {
        objeto.enviado = true
      })
    },
    obteneAgendasSinEnviar () {
      const objetosNoEnviados = this.items.filter(function (objeto) {
        return objeto.enviado === false
      })
      console.log(objetosNoEnviados)
      return objetosNoEnviados
    },
    existeAgendaParaEnviar () {
      return this.items.some(function (objeto) {
        return objeto.enviado === false
      })
    },
    CrearAgendas () {
      console.log(this.items)
      if (!this.existeAgendaParaEnviar()) {
        const toast = {}
        toast.title = 'Estas agendas ya fueron enviadas.'
        toast.variant = 'warning'
        toast.body = 'Warning'
        this.makeToast(toast)
        return
      }
      if (this.items.length > 0) {
        console.log('es mayor que cero')
        const dataFilter = this.obteneAgendasSinEnviar()
        console.log(dataFilter)
        const data = JSON.stringify(dataFilter)
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://api.appdmetrix.com/api/helpbank/crear/agenda/H01',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        }
        axios.request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data))
            const toast = {}
            this.setAgendasEnviadas()
            toast.title = 'Agendamiento exitoso.'
            toast.variant = 'success'
            toast.body = 'Success'
            this.makeToast(toast)
            this.getSolicitudesAgendas().then((res) => {
              console.log(this.solicitudesAgendas)
              this.items = this.solicitudesAgendas
            })
          })
          .catch((error) => {
            console.log(error)
            const toast = {}
            toast.title = 'Hubo un error.'
            toast.variant = 'danger'
            toast.body = 'Error'
            this.makeToast(toast)
          })
      }
    },
    CrearAgendasV2 () {
      console.log(this.items)
      if (!this.existeAgendaParaEnviar()) {
        const toast = {}
        toast.title = 'Estas agendas ya fueron enviadas.'
        toast.variant = 'warning'
        toast.body = 'Warning'
        this.makeToast(toast)
        return
      }
      if (this.items.length > 0) {
        console.log('es mayor que cero')
        const dataFilter = this.obteneAgendasSinEnviar()
        console.log(dataFilter)
        const data = JSON.stringify(dataFilter)
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://api.appdmetrix.com/api/helpbank/crear/agenda/H02',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        }
        axios.request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data))
            const toast = {}
            toast.title = 'Agendamiento exitoso.'
            toast.variant = 'success'
            toast.body = 'Success'
            this.makeToast(toast)
            this.getSolicitudesAgendas().then((res) => {
              console.log(this.solicitudesAgendas)
              this.items = this.solicitudesAgendas
            })
          })
          .catch((error) => {
            console.log(error)
            const toast = {}
            toast.title = 'Hubo un error.'
            toast.variant = 'danger'
            toast.body = 'Error'
            this.makeToast(toast)
          })
      }
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants') && key !== 'docs') {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'agendamientos')
      XLSX.writeFile(wb, 'agendamientos.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    cargarClientes () {
      router.push({ path: '/uploadClientes' })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('Utils', ['comunasApi']),
    ...mapState('Utils', ['grupos']),
    ...mapState('Utils', ['devices']),
    ...mapState('Utils', ['solicitudesAgendas']),
    ...mapState('Utils', ['comunasV2']),
    ...mapState('Utils', ['codRutas']),
    ...mapState('Utils', ['rutas']),
    ...mapState('Cliente', ['setAgendas'])
  }
}
