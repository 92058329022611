import { mapActions, mapState } from 'vuex'

export default {
  name: 'AsignacionAgendasView',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      selectedComuna: null,
      comunas: [
        { value: null, text: 'Seleccionar Comunas' }
      ],
      selectedGrupo: null,
      grupo: [
        { value: null, text: 'Seleccionar Grupo' }
      ],
      selectedClaveActual: null,
      claveAcrual: [
        { value: null, text: 'Seleccionar Clave' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
        { value: '4', text: '4' },
        { value: 'J', text: 'J' },
        { value: 'L', text: 'L' },
        { value: 'X', text: 'X' }
      ],
      clienteData: {
        cuenta: null,
        cent_operativo: null,
        zonacomercial: null,
        segmentacion: null,
        direccion: null,
        uso: 'Area verde',
        diametro_medidor: null,
        tecnologia_lectura: null,
        ruta_lectura: null,
        mod_med: null,
        numero_serie: null,
        rut: null,
        nombre_completo: null,
        telefono1: null,
        telefono2: null,
        num_cuenta: null,
        lectura_actual: null,
        clave_actual: null,
        info_adicional: null,
        comuna: null,
        zona_facturacion: null
      }
    }
  },
  watch: {
  },
  mounted () {
    const event = {
      tipo_evento: 'areas_verdes'
    }
    this.getUltimoEvento(event).then((res) => {
      console.log(this.ultimoEvento)
    })
    this.getComunas().then((res) => {
      this.comunasApi.sort().forEach(e => {
        this.comunas.push({
          value: e,
          text: e
        })
      })
    })
    this.getGrupos().then((res) => {
      this.grupos.sort().forEach(e => {
        this.grupo.push({
          value: e,
          text: e
        })
      })
    })
  },
  methods: {
    ...mapActions('Utils', [
      'getComunas', 'getGrupos', 'getDevices', 'getSolicitudesAgendas'
    ]),
    ...mapActions('Cliente', [
      'setCliente', 'getUltimoEvento'
    ]),
    crear () {
      if (this.clienteData.cuenta === null || this.clienteData.cuenta === 0 || this.clienteData.num_cuenta === null || this.clienteData.lectura_actual === null ||
        this.selectedComuna === null || this.selectedGrupo === null || this.selectedClaveActual === null || this.clienteData.diametro_medidor === null) {
        const toast = {}
        toast.title = 'Error.'
        toast.variant = 'danger'
        toast.body = 'Faltan campos por completar o corregir'
        this.makeToast(toast)
        return
      }
      this.clienteData.comuna = this.selectedComuna
      this.clienteData.zona_facturacion = this.selectedGrupo
      this.clienteData.cuenta = parseInt(this.clienteData.cuenta)
      this.clienteData.diametro_medidor = parseInt(this.clienteData.diametro_medidor)
      this.clienteData.num_cuenta = parseInt(this.clienteData.num_cuenta)
      this.clienteData.lectura_actual = parseInt(this.clienteData.lectura_actual)
      this.clienteData.clave_actual = this.selectedClaveActual
      console.log(this.clienteData)
      this.setCliente(this.clienteData).then((res) => {
        if ('status' in this.setClienteResponse) {
          if (this.setClienteResponse.status === 200) {
            const toast = {}
            toast.title = 'Creacion exitosa'
            toast.variant = 'success'
            toast.body = 'Success'
            this.makeToast(toast)
          } else {
            const toast = {}
            toast.title = 'Error en creacion'
            toast.variant = 'danger'
            toast.body = 'Error'
            this.makeToast(toast)
          }
        } else {
          if (this.setClienteResponse.response.status === 400) {
            const toast = {}
            toast.title = 'Error en creacion'
            toast.variant = 'danger'
            toast.body = this.setClienteResponse.response.data
            this.makeToast(toast)
          }
        }
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('Utils', ['comunasApi']),
    ...mapState('Utils', ['grupos']),
    ...mapState('Utils', ['devices']),
    ...mapState('Utils', ['solicitudesAgendas']),
    ...mapState('Cliente', ['setClienteResponse']),
    ...mapState('Cliente', ['ultimoEvento'])
  }
}
